@import "../variables";

.EmployeeItem {

    &,
    :global(.cleanslate) & {
        display: flex;
        padding: 25px 30px;
        font-family: $primary_font;
        pointer-events: auto;
        cursor: pointer;
        justify-content: center;
        width: 100%;
        min-height: 133px;
        position: relative;
        border-top: 1px solid transparent;
        background-color: $white;
        transition: border-top-color linear 150ms,
        opacity linear 150ms,
        background-color linear 150ms;
        opacity: 1;
        border-bottom: solid 0.9px $selected_border_color;
        align-items: center;
        user-select: none;
        -webkit-touch-callout: none;
        color: $brand_text;

        @media (max-width: 450px) {
            padding: 25px 22px;
        }

        * {
            cursor: pointer;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 100%;
            margin-top: -1px;
            background-color: transparent;
            opacity: 1;
            transition: background-color linear 150ms,
            opacity linear 150ms,
            width ease-in 150ms;
        }

        .Image {
            .ImagePlaceholder {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $secondary-text-color_alpha_60;
                color: $white;
                width: 97px;
                height: 97px;
                object-fit: contain;
                border-radius: 8px;
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 8px;
                    opacity: 0;
                    transition: opacity ease-in-out 150ms;

                    &.Show {
                        opacity: 1;
                    }
                }
            }
        }

        .Content {
            position: relative;
            flex: 1;
            margin-left: 14px;

            .Arrow {
                opacity: 1;
                transition: opacity ease-in-out 150ms;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%) translateZ(0);
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $white_alpha_70;
                border-radius: 50%;

                @media (max-width: 520px) {
                    right: 10px;
                    bottom: -2px;
                    transform: none;
                    top: auto;
                }

                &::after {
                    content: '';
                    mask: url('../../assets/svg/arrow-right.svg');
                    mask-size: 24px 24px;
                    width: 24px;
                    height: 24px;
                    display: block;
                    background: #909AA7;
                }
            }

            .Name {
                flex: 0 1 120px;
                font-size: 21px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.05;
                letter-spacing: normal;
                transition: opacity linear 150ms;

                .Lock {
                    width: 11px;
                    height: 15px;
                    fill: $primary_color;
                    margin-right: 4px;
                }
            }

            .FirstAvailableTime {
                color: var(--SalonLife__primary_color);
                font-size: 14px;
                line-height: 14px;
                padding-top: 5px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;

                @media (max-width: 500px) {
                    font-size: 12px;
                    line-height: 12px;
                }

                @media (max-width: 450px) {
                    gap: 5px;
                }

                .Label {
                    color: var(--SalonLife__secondary_text);
                }

                .WrapCol {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;

                    @media (max-width: 450px) {
                        gap: 5px;
                    }
                }

                .Value {
                    padding: 8px 12px 7px;
                    background: var(--SalonLife__primary_color_alpha_22);
                    color: var(--SalonLife__primary_color);
                    border-radius: 8px;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 500;

                    &.Opacity {
                        opacity: 0.6;
                    }

                    @media (max-width: 500px) {
                        font-size: 11px;
                        line-height: 11px;
                        border-radius: 6px;
                        padding: 6px 8px 5.5px;
                    }
                }
            }

            .Description {
                font-size: 13px;
                line-height: 1.23;
                color: $secondary-text-color;
                display: block;
                width: 100%;
                padding-top: 7px;
            }

            .ReadMore {
                display: inline-block;
                color: $brand_text;
                padding-left: 4px;
            }

            .Price {
                font-size: 21px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.05;
                letter-spacing: normal;
                color: $brand_text;
                padding-top: 14px;
            }
        }

        &.EmployeeItemSelected {
            //background-color: rgba(239, 242, 244, 0.5);
            background-color: $selected_color;
            border-top-color: $selected_border_color;

            &::before {
                width: 6px;
                background-color: $primary_color;
                opacity: 0.5;
            }

            &::after {
                width: 100%;
            }

            .Content .Arrow {
                opacity: 0;
            }
        }
    }
}
