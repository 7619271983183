$white_alpha_0: var(--SalonLife__white_alpha_0);
$white_alpha_60: var(--SalonLife__white_alpha_60);
$white_alpha_70: var(--SalonLife__white_alpha_70);
$white: var(--SalonLife__white);
$color: #8e9aa8;

$font-white: var(--SalonLife__header_font_color);
$font-white_alt: var(--SalonLife__white_alt);

$primary_color: var(--SalonLife__primary_color);
$primary_color_alpha_22: var(--SalonLife__primary_color_alpha_22);
$primary_color_alpha_50: var(--SalonLife__primary_color_alpha_50);
$purpleish-blue: var(--SalonLife__purpleish_color);
$purpleish_color_alpha: var(--SalonLife__purpleish_color_alpha);
$cornflower: var(--SalonLife__cornflower);
$cornflower-alt: var(--SalonLife__cornflower_alt);
$primary_shadow: var(--SalonLife__primary_shadow);
$header_shadow: var(--SalonLife__header_shadow);
$lavender-blue: var(--SalonLife__lavender_blue);

$secondary_color: var(--SalonLife__secondary_color);
$pale-gray: var(--SalonLife__header_font_color);

$brand_text: var(--SalonLife__brand_text);
$brand_text_alpha_13: var(--SalonLife__brand_text_alpha_13);
$brand_text_alpha_50: var(--SalonLife__brand_text_alpha_50);

$secondary-text-color: var(--SalonLife__secondary_text);
$secondary-text-color_alpha_60: var(--SalonLife__secondary_text__alpha_60);
$secondary-text-color_alpha_14: var(--SalonLife__secondary_text__alpha_14);
$secondary-text-color_alpha_84: var(--SalonLife__secondary_text__alpha_84);

$selected_color: var(--SalonLife__selected_color);
$selected_border_color: var(--SalonLife_selected_border_color);
$selected_border_color_alt: var(--SalonLife_selected_border_color_alt);

$crossed_red: var(--SalonLife_crossed_red);
$error: rgb(249, 103, 103);

$primary_font: 'Online Primary', 'Roboto', sans-serif;
