@import "../variables";

.Review {
    &,
    :global(.cleanslate) & {
        padding: 0 20px;
        font-family: $primary_font;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: $brand_text;

        .Block {

            padding-top: 40px;

            &.Location {
                border-bottom: 1px solid $selected_border_color;
                padding: 40px 0;
                text-align: center;
            }

            .Heading {
                font-size: 29px;
                line-height: 0.76;
                text-align: center;

                @media (max-width: 340px) {

                    font-size: 20px;
                }
            }
        }

        .SalonName {
            font-size: 21px;
            line-height: 1.05;
            text-align: center;
            padding-top: 40px;
        }

        .SalonAddress {
            padding-top: 9px;
            font-size: 13px;
            color: $primary_color;
        }

        .ServiceList {
            border: 0;
            border-bottom: 1px solid $selected_border_color;
            margin-top: 40px;

            .Category {

                position: relative;
                width: 100%;
                line-height: 16px;
                user-select: none;
                -webkit-touch-callout: none;

                &::before {

                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 7px;
                    width: 100%;
                    height: 1px;
                    background: $selected_border_color;
                }

                > span {

                    display: inline-block;
                    width: auto;
                    font-size: 13px;
                    line-height: 16px;
                    font-family: $primary_font;
                    background: $white;
                    padding-right: 14px;
                    padding-left: 14px;
                    position: relative;
                    vertical-align: top;
                    max-width: 95%;
                }
            }

            .Services {

                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                padding: 0 0 0 14px;

                .Service {

                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    align-items: stretch;
                    user-select: none;
                    -webkit-touch-callout: none;

                    .Remove {

                        flex: 0 0 62px;
                        width: 62px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        padding: 18px 0 18px 6px;

                        * {
                            cursor: pointer;
                        }

                        @media (max-width: 340px) {

                            flex: 0 0 42px;
                            width: 42px;
                        }

                        svg {

                            display: block;
                            width: 14px;
                            height: 14px;
                            fill: $primary_color;
                        }
                    }

                    .Name {

                        flex: 1 1 auto;
                        font-family: $primary_font;
                        font-size: 15px;
                        line-height: 1.47;
                        padding: 18px 0;
                        border-bottom: 1px solid $selected_border_color;

                        @media (max-width: 340px) {

                            font-size: 13px;
                        }
                    }

                    .Price {

                        flex: 0 0 121px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        text-align: right;
                        font-family: $primary_font;
                        font-size: 21px;
                        line-height: 21px;
                        padding: 18px 20px 18px 6px;
                        border-bottom: 1px solid $selected_border_color;
                        text-transform: uppercase;

                        @media (max-width: 340px) {

                            font-size: 18px;
                            flex: 0 0 100px;
                        }
                    }

                    &:last-child {

                        .Name,
                        .Price {

                            border-bottom: none;
                        }
                    }
                }
            }

            .Summary {

                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 28px 20px 28px 20px;
                border-top: 1px solid $selected_border_color;

                @media (max-width: 340px) {

                    flex-wrap: wrap;
                }

                .TotalCost {

                    font-size: 15px;
                    line-height: 16px;
                    text-align: left;
                    user-select: none;
                    -webkit-touch-callout: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .Total {

                    text-align: right;
                    user-select: none;
                    -webkit-touch-callout: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-left: 12px;
                    font-size: 29px;
                    color: $brand_text;

                    @media (max-width: 340px) {

                        font-size: 24px;
                    }
                }
            }
        }

        .Employee {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: stretch;
            padding: 40px 0 30px 20px;
            border-bottom: 1px solid $selected_border_color;

            @media (max-width: 340px) {

                padding-left: 0;
            }

            .Image {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-right: 20px;

                img {
                    width: 51px;
                    height: 50px;
                    object-fit: contain;
                    border-radius: 8px;
                }

                .NoImage {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $secondary-text-color;
                    opacity: 0.6;
                    color: $white;
                    width: 51px;
                    height: 50px;
                    object-fit: contain;
                    border-radius: 8px;
                }
            }

            .Info {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                padding: 18px 0;

                .Name {
                    font-size: 21px;
                    line-height: 1.05;

                    @media (max-width: 340px) {

                        font-size: 17px;
                    }
                }

                .Email {
                    font-size: 15px;
                    line-height: 1.47;
                    color: $primary_color;
                }
            }
        }

        .DateTime {
            display: flex;
            justify-content: space-around;
            margin-top: 40px;
            width: 100%;

            .Block {
                display: flex;
                flex-direction: column;
                flex: 0 0 50%;
                text-align: center;
                padding: 15px;
                margin: auto 0;

                &.Date {
                    border-right: 1px solid $selected_border_color;
                }

                .Value {
                    font-size: 29px;
                    color: $brand_text;
                    line-height: 0.76;
                    white-space: nowrap;

                    @media (max-width: 340px) {

                        font-size: 19px;
                    }
                }

                .Type {
                    font-size: 13px;
                    color: $secondary-text-color;
                    margin-top: 9px;
                }

                .TimeType {
                    color: #97a2b0;
                    margin-left: 5px;
                    text-transform: uppercase;
                }
            }
        }

        .ChangeDate {
            margin-top: 29px;
            text-align: center;
            font-size: 13px;
            line-height: 1.23;
            color: $primary_color;
            cursor: pointer;

            * {
                cursor: pointer;
            }
        }

        .ConfirmButton {
            margin: 40px auto 0 auto;
            box-shadow: 0 10px 20px 0 $primary_shadow;
            background-color: $primary_color;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: $white;
            border: none;
            outline: none;
            min-height: 63px;
            border-radius: 31.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 214px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            user-select: none;
            -webkit-touch-callout: none;

            * {
                cursor: pointer;
            }

            > span {
                display: inline-block;
                user-select: none;
                -webkit-touch-callout: none;
            }
        }
    }
}
