@import "../variables";

.ScheduleWeekSelection {

    &,
    :global(.cleanslate) & {
        display: flex;
        //min-height: 70px;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;

        .DatesSideOffset {

            width: 100%;
            padding: 0 16px;
        }

        .Content {
            display: flex;
            width: 100%;

            .Dates {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                min-height: 50px;

                .Arrow {
                    cursor: pointer;
                    user-select: none;
                    -webkit-touch-callout: none;
                    width: 30px;
                    text-align: center;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    * {
                        cursor: pointer;
                    }

                    svg {

                        width: 10px;
                        height: 16px;
                        fill: $primary_color;
                    }

                    &.Left {
                        //margin-right: 10px;
                    }

                    &.Right {
                        //margin-left: 10px;
                    }
                }

                .DateItem {
                    flex-direction: column;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: $primary_font;
                    width: 50px;
                    height: 100%;
                    cursor: pointer;
                    user-select: none;
                    -webkit-touch-callout: none;
                    position: relative;
                    transform: translate3d(0, 0, 0);

                    * {
                        cursor: pointer;
                    }

                    &:focus {

                        outline: 0;
                    }

                    &.Selected {
                        border-radius: 25px;
                        background-color: $primary_color;
                        box-shadow: 0 10px 20px 0 $header_shadow;

                        .Date {
                            color: $white;
                        }

                        .Weekday {
                            color: $secondary_color;
                        }
                    }

                    &.AnotherMonth {

                        .Date {
                            color: $secondary-text-color;
                        }
                    }

                    &.NotAvailable {
                        position: relative;
                        cursor: not-allowed;

                        svg {
                            stroke: $crossed_red;
                            position: absolute;
                            display: block;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 22px;
                            height: 22px;
                        }

                        .Date {
                            opacity: 0.5;
                            color: $secondary-text-color;
                        }

                        .Weekday {
                            opacity: 0.5;
                        }
                    }

                    .Date {
                        font-size: 15px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: $brand_text;
                        line-height: normal;
                    }

                    .Weekday {
                        font-size: 12px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: $secondary-text-color;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
