@import "../variables";

.EmployeeBlock {

    &,
    :global(.cleanslate) & {

        user-select: none;
        -webkit-touch-callout: none;

        .Header {

            display: flex;
            align-items: center;
            border-bottom: solid 0.9px $selected_border_color;
            padding: 13px 30px;

            @media (max-width: 450px) {
                padding: 13px 22px;
            }

            .Title {

                flex-grow: 1;
                font-size: 15px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.47;
                letter-spacing: normal;
                color: $secondary-text-color;
                font-family: $primary_font;
            }
        }

        .Count {

            padding-right: 10px;

            .CountWrapper {

                width: 24px;
                height: 24px;
                object-fit: contain;
                background-color: $primary_color_alpha_22;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                border-radius: 12px;

                .CountText {

                    font-family: $primary_font;
                    font-size: 10px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $primary_color;
                }
            }
        }
    }
}
