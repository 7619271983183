@import "../variables";

.Wrapper {

    &,
    :global(.cleanslate) & {

        position: absolute;
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        height: auto;
        top: 0;
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: ease-in-out transform 150ms, ease-in-out opacity 150ms;
        transform: translate3d(0, 100%, 0);
        opacity: 0;
        outline: 0;

        &.Show {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}

.Lock,
.HourGlass {

    &,
    :global(.cleanslate) & {
        fill: $white;
        margin-bottom: 32px;
    }
}

.Lock {

    &,
    :global(.cleanslate) & {
        width: 34px;
        height: 40px;

    }
}

.HourGlass {

    &,
    :global(.cleanslate) & {
        width: 25px;
        height: 40px;
    }
}

.Title {

    &,
    :global(.cleanslate) & {
        font-family: $primary_font;
        font-size: 25px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        margin-bottom: 15px;
    }
}

.Description {
    &,
    :global(.cleanslate) & {
        font-family: $primary_font;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
        max-width: 340px;
        padding: 0 20px;
        margin-bottom: 50px;
    }
}

.InputWrapper {

    &,
    :global(.cleanslate) & {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        position: relative;

        .WrongCode {
            position: absolute;
            top: 50px;
            border-radius: 18px;
            border: solid 1px $error;
            background-color: $purpleish_color_alpha;
            font-family: $primary_font;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            color: $white;
            margin-top: 16px;
            padding: 7.5px 30px;
        }
    }
}

.Background {

    &,
    :global(.cleanslate) & {

        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: $primary_color;
        z-index: 2;
        transition: ease-in-out transform 150ms, ease-in-out opacity 150ms;
        transform: translate3d(0, 100%, 0);
        opacity: 0;

        &.Show {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}
