@font-face {
    font-family: 'Online Primary';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('../assets/fonts/Primary500.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Primary500.otf') format('opentype'),
    url('../assets/fonts/Primary500.woff') format('woff'),
    url('../assets/fonts/Primary500.ttf') format('truetype'),
    url('../assets/fonts/Primary500.svg#Gotham-Medium') format('svg');
}

@font-face {
    font-family: 'Online Primary';
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    src: url('../assets/fonts/Primary400.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Primary400.otf') format('opentype'),
    url('../assets/fonts/Primary400.woff') format('woff'),
    url('../assets/fonts/Primary400.ttf') format('truetype'),
    url('../assets/fonts/Primary400.svg#Gotham-Medium') format('svg');
}

@font-face {
    font-family: 'Online Primary';
    font-style: italic;
    font-display: swap;
    font-weight: 500;
    src: url('../assets/fonts/Primary500Italic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Primary500Italic.otf') format('opentype'),
    url('../assets/fonts/Primary500Italic.woff') format('woff'),
    url('../assets/fonts/Primary500Italic.ttf') format('truetype'),
    url('../assets/fonts/Primary500Italic.svg#Gotham-Medium') format('svg');
}

@font-face {
    font-family: 'Online Primary';
    font-style: italic;
    font-display: swap;
    font-weight: normal;
    src: url('../assets/fonts/Primary400Italic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Primary400Italic.otf') format('opentype'),
    url('../assets/fonts/Primary400Italic.woff') format('woff'),
    url('../assets/fonts/Primary400Italic.ttf') format('truetype'),
    url('../assets/fonts/Primary400Italic.svg#Gotham-Medium') format('svg');
}

@font-face {
    font-family: 'RwWidgets';
    font-weight: normal;
    font-style: normal;
    src: url('../assets/fonts/rw-widgets.eot?v=4.1.0');
    src: url('../assets/fonts/rw-widgets.eot?#iefix&v=4.1.0') format('embedded-opentype'),
    url('../assets/fonts/rw-widgets.woff?v=4.1.0') format('woff'),
    url('../assets/fonts/rw-widgets.ttf?v=4.1.0') format('truetype'),
    url('../assets/fonts/rw-widgets.svg?v=4.1.0#fontawesomeregular') format('svg')
}
