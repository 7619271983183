@import "../variables";

.Header {

    &,
    :global(.cleanslate) & {
        box-shadow: 0 10px 20px 0 $header_shadow;
        //background-color: var(--primary_color);
        background-color: $primary_color;
        text-align: center;
        color: $font-white;
        padding: 34px;
        //z-index: 1;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        display: flex;
        height: 90px;

        @media (max-width: 450px) {
            padding: 34px 22px;
        }

        &.Simple {
            background-color: $white;
            box-shadow: none;
            border-bottom: solid 0.9px $selected_border_color;
            padding: 34px 70px 34px 40px;
        }
    }
}

.Navigator {

    &,
    :global(.cleanslate) & {
        width: 36px;
        max-width: 36px;
        flex: 0 1 36px;
        min-height: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {

            fill: $secondary_color;
            width: 16px;
            height: 16px;
            display: block;
            cursor: pointer;

            * {
                cursor: pointer;
            }
        }

        &.Simple {
            svg {
                fill: $primary_color;
            }
        }
    }
}

.Heading {

    &,
    :global(.cleanslate) & {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        user-select: none;
        -webkit-touch-callout: none;
        flex-direction: column;

        .Title,
        .SalonName {

            font-family: $primary_font;
            font-style: normal;
            font-stretch: normal;
            text-align: center;
            letter-spacing: normal;
            width: 100%;
            color: $pale-gray;
            user-select: none;
            -webkit-touch-callout: none;
        }

        .Title {

            font-size: 20.3px;
            font-weight: 500;
            line-height: 22px;

            &.Simple {
                color: $brand_text;
            }
        }

        .SalonName {

            font-size: 12px;
            font-weight: normal;
            line-height: 16px;
            color: $secondary_color;
            user-select: text;
            -webkit-touch-callout: default;
            margin-top: 5px;

            max-height: 32px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.Lang {

    &,
    :global(.cleanslate) & {
        width: 44px;
        max-width: 44px;
        flex: 0 1 44px;
        min-height: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > div {

            width: 24px;
            height: 18px;
            cursor: pointer;
            user-select: none;
            -webkit-touch-callout: none;

            * {
                cursor: pointer;
            }

            svg {

                display: block;
                width: 24px;
                height: 18px;
            }
        }
    }
}
