*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

body,
html {

    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    position: relative;
    background: #cecece;
    overflow: hidden;
}

body {

    -webkit-overflow-scrolling: auto;
}

#root {

    height: 100%;
    padding: 50px 0 0;

    @media (max-width: 701px) {
        padding: 0;
    }
}
