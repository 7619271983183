@import "../variables";

.FormInput {

    &,
    :global(.cleanslate) & {
        height: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        position: relative;
        caret-color: $primary_color;
        font-family: $primary_font;
        border: 1px solid transparent;
        transition: border ease-in-out 150ms;

        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: 1px;
            background: $selected_border_color;
        }

        &.Selected {
            //background-color: rgba(239, 242, 244, 0.5);
            background-color: $selected_color;
            border-right: 6px solid $primary_color_alpha_50;

            &::after {

                width: 100%;
            }
        }

        &.TextArea {

            padding-bottom: 19px;
            height: auto;

            .Logo {
                align-self: flex-start;
                margin-top: 30px;
            }

            svg {

                width: auto;
                height: auto;
            }

            label {
                margin-top: 23px;
            }
        }

        &.Error {
            border-top: 1px solid;
            border-left: 1px solid;
            border-bottom: 1px solid;
            border-color: $crossed_red;
        }

        .Logo {
            min-width: 92px;
            display: flex;
            justify-content: center;

            svg {
                fill: $primary_color;
            }
        }

        label.Label {
            width: 100%;
            border-radius: 3px;
            border: 0;
            position: relative;
            margin-right: 20px;

            span.Text {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                margin-bottom: 4px;
                text-align: left;
                height: 16px;
                font-family: $primary_font;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #7789AD;
            }

            span.BirthdayPlaceholder {
                padding: 0;
                width: 100%;
                display: block;
                background: transparent;
                border: 0;
                height: 22px;
                margin: 0;
                font-family: $primary_font;
                font-size: 21px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.05;
                letter-spacing: normal;
                color: $brand_text;
                user-select: none;
                -webkit-touch-callout: none;

                &.NotSelected {
                    color: #7789AD;
                }

                .DateCol1 {
                    display: inline-block;
                    padding-right: 20px;
                    width: 49px;
                }

                .DateCol2 {
                    display: inline-block;
                    padding-right: 20px;
                    padding-left: 20px;
                    width: 69px;
                }

                .DateCol3 {
                    display: inline-block;
                    padding-left: 20px;
                }

                .DateCol1,
                .DateCol2,
                .DateCol3 {
                    cursor: pointer;
                    position: relative;

                    .Select {
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            input {
                padding: 0;
                width: 100%;
                border: 0;
                box-shadow: none;
                display: block;
                background: transparent;
                margin: 0;
                text-align: left;
                height: 22px;
                font-family: $primary_font;
                font-size: 21px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.05;
                letter-spacing: normal;
                color: $brand_text;
                outline: none;
            }

            textarea {
                padding: 0;
                width: 100%;
                border: 0;
                box-shadow: none;
                display: block;
                background: transparent;
                margin: 0;
                text-align: left;
                font-family: $primary_font;
                font-size: 17px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.41;
                letter-spacing: normal;
                color: $brand_text;
                outline: none;
                resize: none;
            }
        }
    }
}
