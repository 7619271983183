@import "../variables";

.Wrapper {

    &,
    :global(.cleanslate) & {

        position: absolute;
        width: 100%;
        max-height: 100%;
        height: auto;
        padding: 40px 60px 70px;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: ease-in-out transform 150ms, ease-in-out opacity 150ms;
        transform: translate3d(0, 100%, 0);
        opacity: 0;
        box-shadow: 0 -10px 20px 0 $primary_color_alpha_22;
        background-color: $primary_color;
        outline: 0;

        &.Show {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}

.Background {

    &,
    :global(.cleanslate) & {

        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: rgba($white, 0.7);
        z-index: 2;
        transition: ease-in-out transform 150ms, ease-in-out opacity 150ms;
        transform: translate3d(0, 100%, 0);
        opacity: 0;

        &.Show {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}

.Close {

    &,
    :global(.cleanslate) & {
        position: absolute;
        top: 25px;
        right: 20px;
        user-select: none;
        -webkit-touch-callout: none;
        cursor: pointer;

        svg {
            width: 18px;
            height: 18px;
            fill: $pale-gray;
            user-select: none;
            -webkit-touch-callout: none;
            pointer-events: none;
        }
    }
}
