@import "../variables";

.LanguageSwitcher {

    &,
    :global(.cleanslate) & {
        width: 100%;
        position: absolute;
        top: 90px;
        left: 0;
        background: $white_alpha_70;
        opacity: 0;
        transition: opacity ease-in 150ms, transform ease-out 100ms;
        height: calc(100% - 90px);
        transform: translate3d(0, 100%, 0) translateZ(0);
        z-index: 2;

        &.Show {

            opacity: 1;
            transform: translate3d(0, 0, 0) translateZ(0);
        }
    }
}

.Box {

    &,
    :global(.cleanslate) & {
        position: absolute;
        bottom: 0;
        left: 0;
        max-height: 90%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        object-fit: contain;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        box-shadow: 0 0 20px $secondary-text-color_alpha_14;
        background-color: $white;
        padding: 30px 30px 50px;
        transform: translate3d(0, 100%, 0);
        transition: opacity ease-in 150ms, transform ease-in 150ms;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        z-index: 2;

        &.Show {

            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}

.Heading {

    &,
    :global(.cleanslate) & {
        font-family: $primary_font;
        font-size: 25px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $brand_text;
        margin-bottom: 25px;
        user-select: none;
        -webkit-touch-callout: none;
    }
}

.Container {

    &,
    :global(.cleanslate) & {
        display: block;
        width: 100%;
        padding: 0 10px;
    }
}

.Language {

    &,
    :global(.cleanslate) & {
        width: 100%;
        padding: 13px;
        min-height: 48px;
        border-radius: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
        font-family: $primary_font;
        font-size: 18px;
        color: $brand_text;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        outline: 0;
        box-shadow: 0 10px 20px 0 transparent;
        border: 0;
        background: transparent;
        cursor: pointer;
        user-select: none;
        -webkit-touch-callout: none;

        * {
            cursor: pointer;
        }

        &:focus {

            outline: 0;
            box-shadow: 0 10px 20px 0 transparent;
            border: 0;
        }

        transition: background linear 150ms,
        box-shadow linear 150ms;

        &.Active {

            box-shadow: 0 10px 20px 0 $primary_shadow;
            background-color: $primary_color;
            color: $white;
            font-weight: 500;
        }

        &:last-child {

            margin-bottom: 0;
        }
    }
}
