.WebWrapper {

    max-width: 700px;
    margin: 0 auto;
    height: 100%;
    //overflow: hidden;

    /* mobile viewport bug fix */
    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }

    > div {

        height: 100%;

        /* mobile viewport bug fix */
        @supports (-webkit-touch-callout: none) {
            height: -webkit-fill-available;
        }
    }

    > svg {

        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 0;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        transition: opacity ease-in 500ms;
        opacity: 0;
    }

    @media (max-width: 701px) {

        > div,
        > div > div {

            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
