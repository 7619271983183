@import "../variables";

.Confirm {

    &,
    :global(.cleanslate) & {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
        transition: transform ease-in-out 150ms,
        opacity linear 150ms;

        &.Show {

            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
}

.ConfirmBox {

    &,
    :global(.cleanslate) & {

        width: 100%;
        object-fit: contain;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        box-shadow: 0 -10px 20px 0 $primary_shadow;
        background-color: $primary_color;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        text-align: center;
        padding: 68px 34px 34px;
        user-select: none;
        -webkit-touch-callout: none;
        max-height: 80%;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;

        .Close {

            position: absolute;
            top: 25px;
            right: 20px;
            cursor: pointer;
            user-select: none;
            -webkit-touch-callout: none;
            background: $primary_color;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {

                width: 10px;
                height: 10px;
                fill: $pale-gray;

                &,
                & * {
                    user-select: none;
                    -webkit-touch-callout: none;
                    pointer-events: none;
                }
            }
        }

        &.Employee {
            background-color: $white;
            box-shadow: 0 10px 20px 0 $secondary-text-color_alpha_84;
            padding: 50px 34px 51px;
        }

        .Title {

            font-family: $primary_font;
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.18;
            letter-spacing: normal;
            text-align: center;
            color: $pale-gray;
            max-width: 238px;
            margin: 0 auto 24px;

            &.EmployeeTitle {
                color: $brand_text;
            }
        }

        .Description {

            font-family: $primary_font;
            font-size: 17px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: center;
            //color: rgba(255, 255, 255, 0.7);
            color: $pale-gray;
            opacity: .7;
            max-width: 294px;
            margin: 0 auto 45px;

            a {

                color: rgba(255, 255, 255, 0.7);
                transition: color ease-in-out 150ms;

                &:hover {

                    color: rgba(255, 255, 255, 1);
                }
            }

            &.EmployeeDescription {
                color: #97a2b0;
                font-style: italic;
            }
        }

        .EmployeeImage {
            padding-bottom: 34px;

            img {
                width: 95px;
                height: 95px;
                border-radius: 14.4px;
            }

            .NoImage {
                background-color: $secondary-text-color;
                opacity: 0.6;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 95px;
                height: 95px;
                object-fit: contain;
                text-align: center;
                border-radius: 14.4px;
                margin: 0 auto;
            }
        }

        .ScheduleButton {
            width: auto;
            min-width: 163px;
            height: auto;
            object-fit: contain;
            border-radius: 22px;
            background-color: $primary_color;
            font-family: $primary-font;
            font-weight: 500;
            color: $white;
            font-size: 16px;
            line-height: 1.38;
            cursor: pointer;
            outline: none;
            border: 0;
            box-shadow: none;
            padding: 11px 25px;

            * {
                cursor: pointer;
            }
        }
    }
}

.Dismiss {

    &,
    :global(.cleanslate) & {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $purpleish_color_alpha;
        margin: 0 auto;
        cursor: pointer;

        * {
            cursor: pointer;
        }

        svg {

            fill: $pale-gray;
            width: 18px;
            height: 13px;
        }
    }
}
