@import "../variables";

.Search {

    &,
    :global(.cleanslate) & {
        width: 100%;
        border-bottom: solid 0.9px $selected_border_color;
        height: 68px;
        position: relative;

        @media (max-width: 450px) {
            height: 56px;
        }

        > input[type="text"] {

            width: 100%;
            height: 68px;
            outline: 0;
            box-shadow: none;
            border: 0;
            font-family: $primary_font;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: $brand_text;
            transition: color ease-in-out 150ms;
            padding: 0 34px 0 70px;
            background: transparent;
            margin: 0;

            @media (max-width: 450px) {
                padding: 0 22px 0 58px;
                font-size: 14px;
                height: 56px;
            }

            &:hover,
            &:focus {
                outline: 0;
                box-shadow: none;
                border: 0;
            }

            &::placeholder {
                color: $secondary-text-color;
                opacity: 1;
                user-select: none;
                -webkit-touch-callout: none;
            }

            &:-ms-input-placeholder {
                color: $secondary-text-color;
                user-select: none;
                -webkit-touch-callout: none;
            }

            &::-ms-input-placeholder {
                color: $secondary-text-color;
                user-select: none;
                -webkit-touch-callout: none;
            }
        }

        > svg {

            stroke: $primary_color;
            width: 22px;
            height: 22px;
            position: absolute;
            left: 34px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            @media (max-width: 450px) {
                left: 22px;
                width: 18px;
                height: 18px;
            }

            * {
                cursor: pointer;
            }
        }
    }
}
