@import "../variables";

.App {

    &,
    :global(.cleanslate) & {

        &,
        > * {

            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        width: 100%;

        max-width: 454px;
        max-height: 0;
        transition: transform 100ms linear,
        opacity 75ms linear;
        overflow: hidden;
        opacity: 0;
        padding: 20px;
        margin: -20px -20px 0;
        position: relative;
        display: flex;
        transform: translateY(100%);

        @media (max-width: 500px) {
            padding: 10px;
            margin: -10px;
        }

        &.Open {
            max-height: calc(100% - 40px);
            opacity: 1;
            transform: translateY(0);
            height: 100%;

            @media (max-width: 500px) {
                max-height: calc(100% - 20px);
            }
        }
    }
}

.AppContainer {

    &,
    :global(.cleanslate) & {

        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        background: $white;
        background: linear-gradient(transparent, 90px, $white 90px);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 0 0 20px $secondary_text-color_alpha_14;
        width: 100%;
    }
}

.AppContent {

    &,
    :global(.cleanslate) & {

        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        position: relative;
        /*backface-visibility: hidden;
        -webkit-backface-visibility: hidden;*/
        flex: 1;
        padding-top: 0;
        transition: padding-top ease-in 150ms;
        scroll-behavior: smooth;

        &.NoScroll {

            overflow-y: hidden;
            -webkit-overflow-scrolling: auto;
            pointer-events: none;
        }

        &.ShowCart {

            padding-top: 89px;
        }

        &.ResultView {
            background-color: $primary_color;
        }
    }
}

.AppContentWrapper {

    &,
    :global(.cleanslate) & {

        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        flex: 1;
        //padding: 0 20px 0;

        .AppContentHeight {

            min-height: calc(100% - 75px);
        }

        &.FillBackground {

            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            background: $primary_color;

            @media (max-width: 701px) {

                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}
