@import "../variables";

.Terms {

    &,
    :global(.cleanslate) & {
        padding: 40px;
        font-family: $primary_font;
        color: $brand_text;
        font-size: 12px;
        text-align: justify;
        line-height: 1.5;

        .Block {
            margin-bottom: 40px;
        }

    }
}