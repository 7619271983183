@import "../variables";

.Booking {
    &,
    :global(.cleanslate) & {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        font-family: $primary_font;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        padding: 0 40px;

        @media (max-width: 375px) {

            padding: 0 40px;
        }

        .Container {
            display: block;
            min-height: 100%;
        }

        .Image {
            width: 182px;
            height: 182px;
            box-shadow: inset 0 0 106px 25px $selected_border_color;
            border: solid 1.7px $selected_border_color_alt;
            border-radius: 50%;
            margin: 135px auto 88px auto;
            display: flex;
            align-items: center;

            &.Error {
                width: 100%;
                height: 100%;
                border: 0;
                box-shadow: none;
                margin: 160px auto 41px auto;

                @media (max-width: 414px) {

                    margin: 75px auto 50px auto;
                }

                .Icon {
                    svg {
                        width: 100%;
                        height: auto;
                        fill: $white;
                    }

                    img {

                        width: 363px;
                        max-width: 100%;
                        height: auto;
                        user-select: none;
                        -webkit-touch-callout: none;
                    }
                }
            }

            @media (max-width: 414px) {

                margin: 75px auto 70px auto;
                width: 150px;
                height: 150px;
            }

            .Icon {
                margin: 0 auto;
                text-align: center;

                svg {
                    width: 78px;
                    height: 59px;
                    fill: $white;
                }

            }
        }

        .Header {
            font-size: 39px;
            font-weight: 900;
            text-transform: uppercase;

            &.Error {
                text-transform: unset;
                font-size: 30px;

                @media (max-width: 375px) {

                    font-size: 20px;
                }
            }

            @media (max-width: 375px) {

                font-size: 30px;
            }
        }

        .SubHeader {
            font-size: 16px;
            line-height: 1.38;
            margin-top: 10px;
            margin-bottom: 118px;

            &.Error {
                margin-top: 25px;
            }

            @media (max-width: 414px) {

                margin-bottom: 70px;
            }
        }

        .Buttons {

            display: flex;
            justify-content: center;

            &.BookingConfirmed {
                flex-direction: column;
                align-items: center;
            }

            .ActionButton {
                background-color: $brand_text_alpha_13;
                font-size: 16px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: center;
                color: $white;
                border: none;
                outline: none;
                min-height: 63px;
                border-radius: 31.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 204px;
                width: 100%;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                user-select: none;
                -webkit-touch-callout: none;
                margin-bottom: 20px;

                * {
                    cursor: pointer;
                }

                > span {
                    display: inline-block;
                    user-select: none;
                    -webkit-touch-callout: none;
                }
            }
        }
    }
}
