@import "../variables";

.SaveButtonContainer {

    &,
    :global(.cleanslate) & {
        position: sticky;
        bottom: 75px;
        left: 0;
        transform: translateZ(0);
        justify-self: flex-end;
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;

        .SaveButton {
            min-width: 126px;
            height: 63px;
            border-radius: 32px;
            //position: absolute;
            left: 50%;
            bottom: 75px;
            box-shadow: 0 10px 20px 0 $primary_shadow;
            background-color: $primary_color;
            cursor: pointer;
            opacity: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-family: $primary_font;
            color: $white;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.38;

            * {
                cursor: pointer;
            }

            @media (max-width: 414px) {

                height: 50px;
                bottom: 70px;
            }

            &.Show {

                //transform: translate3d(-50%, 0, 0);
                opacity: 1;
            }
        }
    }
}