@import "../variables";

.Confirm {

    &,
    :global(.cleanslate) & {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        opacity: 0;
        transform: translate3d(0, -100%, 0);
        transition: transform ease-in-out 150ms,
        opacity linear 150ms;

        &.Show {

            opacity: 1;
            transform: translate3d(0, 0, 0);
            //langz-index: 2;
        }
    }
}

.ConfirmBox {

    &,
    :global(.cleanslate) & {

        width: 100%;
        object-fit: contain;
        border-radius: 20px;
        box-shadow: 0 10px 20px 0 $primary_shadow;
        background-color: $primary_color;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        text-align: center;
        padding: 34px;

        user-select: none;
        -webkit-touch-callout: none;

        .Title {

            font-family: $primary_font;
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.18;
            letter-spacing: normal;
            text-align: center;
            color: $pale-gray;
            max-width: 238px;
            margin: 0 auto 24px;
        }

        .Description {

            font-family: $primary_font;
            font-size: 19px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.32;
            letter-spacing: normal;
            text-align: center;
            color: $white;
            max-width: 284px;
            margin: 0 auto 50px;
        }
    }
}

.PrimaryButton,
.SecondaryButton {

    &,
    :global(.cleanslate) & {
        width: 100%;
        min-height: 44px;
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        font-family: $primary_font;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: normal;
        cursor: pointer;
        border: 1px solid transparent;
        transition: border linear 150ms,
        background ease-in 150ms,
        color linear 150ms;

        * {
            cursor: pointer;
        }
    }
}

.PrimaryButton {

    &,
    :global(.cleanslate) & {
        background: $white;
        color: $primary_color;

        &:hover {

            background: $primary_color;
            color: $white;
            border-color: $white;
        }
    }
}

.SecondaryButton {

    &,
    :global(.cleanslate) & {
        background: $brand_text_alpha_13;
        color: $white;

        &:hover {

            background: transparent;
            color: $white;
            border-color: $brand_text_alpha_13;
        }
    }
}

.Buttons {

    &,
    :global(.cleanslate) & {
        display: flex;
        margin: 0 -10px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .Button {

            padding: 0 10px;
            flex: 1 1 100px;
            width: 100%;
        }
    }
}