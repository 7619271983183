@import "../variables";

.Footer {

    &,
    :global(.cleanslate) & {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        //flex: 0 1 75px;
        height: 75px;
        min-height: 75px;
        position: relative;
        //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

        background: -moz-linear-gradient(top, $white_alpha_0 0%, $white_alpha_60 25%, $white 60%);
        background: -webkit-linear-gradient(top, $white_alpha_0 0%, $white_alpha_60 25%, $white 60%);
        background: linear-gradient(to bottom, $white_alpha_0 0%, $white_alpha_60 25%, $white 60%);


        &:global(.sticky) {

            position: sticky;
            position: -webkit-sticky;
            bottom: 0;
            left: 0;
            //transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            justify-self: flex-end;
        }

        &.Simple {
            background: none;
            position: relative;

            .copyright,
            a.copyright {
                color: $white;
                margin-bottom: 30px;
            }
        }

        .copyright,
        a.copyright {

            user-select: none;
            -webkit-touch-callout: none;
        }
    }
}

.copyright,
a.copyright {

    &,
    :global(.cleanslate) & {
        font-family: $primary_font;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $secondary-text-color;
        transition: color 150ms linear;

        > * {
            margin-left: 7px;
        }

        > svg {
            fill: $primary_color;
            width: 7px;
            height: 7px;
        }
    }
}

a.copyright {

    &,
    :global(.cleanslate) & {

        text-decoration: none;

        &:hover {

            text-decoration: none;
            color: $cornflower;
        }
    }
}

.Progress {

    &,
    :global(.cleanslate) & {
        display: flex;
        padding: 1px;
        margin: 13px 1px 0;
        width: 100%;

        .ProgressItem {

            flex-grow: 1;
            flex-basis: 0;
            padding: 0 1px;

            .ProgressEntity {

                background: $selected_color;
                height: 6px;
                width: 100%;
                position: relative;

                &::after {

                    content: '';
                    background: $selected_color;
                    width: 0;
                    height: 6px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: all 150ms linear;
                }

                &.Active {

                    &::after {

                        width: 100%;
                        background: $primary_color;
                    }
                }
            }
        }
    }
}

.NextSection {

    &,
    :global(.cleanslate) & {

        width: 64px;
        height: 64px;
        border-radius: 32px;
        position: absolute;
        left: 50%;
        top: -32px;
        box-shadow: 0 10px 20px 0 $primary_shadow;
        background-color: $primary_color;
        cursor: pointer;
        opacity: 0;

        transition: transform ease-in 150ms,
        opacity linear 150ms;

        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate3d(-500px, 0, 0);

        * {
            cursor: pointer;
        }

        svg {
            fill: $pale-gray;
            width: 16px;
            height: 16px;
            display: block;
        }

        &.Show {

            transform: translate3d(-50%, 0, 0);
            opacity: 1;
        }

        &.Disabled {

            cursor: not-allowed;
            pointer-events: none;
        }

        &.ConfirmButton {
            max-width: 214px;
            width: 100%;
            color: $white;
            font-size: 16px;
            font-weight: 500;
            font-family: $primary_font;

            > span {
                display: inline-block;
                user-select: none;
                -webkit-touch-callout: none;
            }
        }

        :global(.Loading) {
            padding: 32px 0;

            > div {

                position: absolute;
                width: 32px;
                height: 32px;
                top: 16px;
                left: 16px;
                border-left-color: $secondary_color;
            }
        }
    }
}
