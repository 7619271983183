.HiddenSubmit {

    &,
    :global(.cleanslate) & {
        position: absolute;
        background: transparent;
        width: 0;
        height: 0;
        top: -99990em;
        left: -99999em;
        border: none;
        box-shadow: none;
        padding: 0;
        opacity: 0;
    }
}
