@import "../variables";

.ShoppingCart {

    &,
    :global(.cleanslate) & {
        width: 100%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        box-shadow: 0 10px 20px 0 $primary_shadow;
        background-color: $primary_color;
        min-height: 89px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: -89px;
        left: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity linear 150ms,
        top ease-in 150ms,
        height ease-in-out 150ms;
        flex-direction: column;
        z-index: 2;
        max-height: calc(100% - 180px);

        &.Opened {

            overflow-y: auto;

            .Contents {
                pointer-events: auto;
            }
        }

        &::before {

            content: '';
            position: absolute;
            top: -90px;
            left: 0;
            width: 100%;
            height: 90px;
            box-shadow: 0 10px 20px 0 $header_shadow;
            z-index: 3;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        &.Show {

            top: 90px;
            opacity: 1;
        }

        .Row {

            min-height: 89px;
            padding: 15px 34px 15px 34px;
            align-items: center;

            @media (max-width: 450px) {
                padding: 15px 22px 15px 22px;
            }
        }

        &.Toggle {

            .Row {

                cursor: pointer;

                * {
                    cursor: pointer;
                }
            }
        }


        .Row {

            display: flex;
            justify-content: center;
            width: 100%;
        }

        .Contents {

            display: block;
            width: 100%;
            padding: 0 20px 0 20px;
            pointer-events: none;
        }

        .Icon {

            width: 36px;
            max-width: 36px;
            flex: 0 1 36px;
            min-height: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            svg {

                display: block;
                width: 20px;
                height: 19px;

                > g {

                    stroke: $secondary_color;
                }

                &,
                & * {

                    user-select: none;
                    -webkit-touch-callout: none;
                    pointer-events: none;
                }
            }
        }

        .Title {

            flex: 1 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            user-select: none;
            -webkit-touch-callout: none;
            flex-direction: column;

            font-family: $primary_font;
            font-size: 20.3px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $pale-gray;

            @media (max-width: 450px) {
                font-size: 16px;
            }
        }

        .Count {

            width: 44px;
            max-width: 44px;
            flex: 0 1 44px;
            min-height: 16px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .Total {

                display: flex;
                min-width: 34px;
                height: 34px;
                text-align: center;
                align-items: center;
                justify-content: center;
                background-color: $cornflower;
                border-radius: 17px;

                > span {

                    font-family: $primary_font;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 14px;
                    color: $pale-gray;
                    letter-spacing: -1px;
                    user-select: none;
                    -webkit-touch-callout: none;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
}

.Category {

    &,
    :global(.cleanslate) & {
        position: relative;
        width: 100%;
        line-height: 16px;
        user-select: none;
        -webkit-touch-callout: none;

        &::before {

            content: '';
            position: absolute;
            left: 0;
            bottom: 7px;
            width: 100%;
            height: 1px;
            background: $cornflower-alt;
        }

        > span {

            display: inline-block;
            width: auto;
            font-size: 13px;
            line-height: 16px;
            font-family: $primary_font;
            color: $lavender-blue;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            background: $primary_color;
            padding-right: 14px;
            padding-left: 14px;
            position: relative;
            z-index: 1;
            vertical-align: top;
            max-width: 95%;
        }
    }
}

.Services {

    &,
    :global(.cleanslate) & {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 0 0 0 14px;

        .Service {

            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: stretch;
            user-select: none;
            -webkit-touch-callout: none;

            .Remove {

                flex: 0 0 62px;
                width: 62px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                padding: 18px 0 18px 6px;

                * {
                    cursor: pointer;
                }

                @media (max-width: 340px) {

                    flex: 0 0 42px;
                    width: 42px;
                }

                svg {

                    display: block;
                    width: 14px;
                    height: 14px;
                    fill: $font-white_alt;

                    &,
                    & * {

                        user-select: none;
                        -webkit-touch-callout: none;
                        pointer-events: none;
                    }
                }
            }

            .Name {

                flex: 1 1 auto;
                font-family: $primary_font;
                font-size: 15px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.47;
                letter-spacing: normal;
                color: $font-white_alt;
                padding: 18px 0;
                border-bottom: 1px solid $cornflower-alt;

                @media (max-width: 340px) {

                    font-size: 13px;
                }
            }

            .Price {

                flex: 0 0 121px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
                font-family: $primary_font;
                font-size: 21px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 21px;
                letter-spacing: normal;
                color: $font-white_alt;
                padding: 18px 20px 18px 6px;
                border-bottom: 1px solid $cornflower-alt;
                text-transform: uppercase;

                @media (max-width: 340px) {

                    font-size: 18px;
                    flex: 0 0 100px;
                }
            }

            &:last-child {

                .Name,
                .Price {

                    border-bottom: none;
                }
            }
        }
    }
}

.Footer {

    &,
    :global(.cleanslate) & {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 28px 20px 28px 20px;
        border-top: 1px solid $cornflower-alt;

        @media (max-width: 340px) {

            flex-wrap: wrap;
            justify-content: center;
        }

        .Clear {

            font-family: $primary_font;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 16px;
            letter-spacing: normal;
            text-align: left;
            color: $lavender-blue;
            user-select: none;
            -webkit-touch-callout: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            * {
                cursor: pointer;
            }
        }

        .Total {

            font-family: $primary_font;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: right;
            color: $lavender-blue;
            user-select: none;
            -webkit-touch-callout: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media (max-width: 340px) {

                margin-top: 10px;
            }

            strong {

                margin-left: 12px;
                font-family: $primary_font;
                font-size: 29px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                text-align: right;
                color: $font-white_alt;

                @media (max-width: 340px) {

                    font-size: 24px;
                }
            }
        }
    }
}
