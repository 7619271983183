@import "../variables";

.InputCheckItem {

    &,
    :global(.cleanslate) & {
        display: flex;
        padding: 30px 30px;
        font-family: $primary_font;
        pointer-events: auto;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 133px;
        position: relative;
        border-top: 1px solid transparent;
        background-color: $white;
        transition: border-top-color linear 150ms,
        opacity linear 150ms,
        background-color linear 150ms;
        //overflow: hidden;
        opacity: 1;

        * {
            cursor: pointer;
        }

        &.Category {

            min-height: 99px;
        }

        &::before {

            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 100%;
            margin-top: -1px;
            background-color: transparent;
            opacity: 1;
            transition: background-color linear 150ms,
            opacity linear 150ms,
            width ease-in 150ms;
        }

        &::after {

            content: '';
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 40px);
            height: 1px;
            background-color: $selected_border_color;
            transition: width ease-in 150ms;
            position: absolute;
        }

        .Radio {

            max-width: 45px;
            flex: 0 1 45px;
            padding-right: 29px;
            width: 45px;

            .RadioButton {

                width: 16px;
                height: 16px;
                border: solid 1.6px $secondary-text-color;
                background: $white;
                border-radius: 8px;
                opacity: 1;
                transition: opacity linear 150ms,
                background-color linear 150ms,
                border linear 150ms;
            }
        }

        &.Disabled {

            .Radio {

                .RadioButton {

                    opacity: 0.3;
                }
            }
        }

        &.InputCheckItemChecked {

            //background-color: rgba(239, 242, 244, 0.5);
            background-color: $selected_color;
            border-top-color: $selected_border_color;

            &::before {

                width: 6px;
                background-color: $primary_color;
                opacity: 0.5;
            }

            &::after {

                width: 100%;
            }

            .Radio .RadioButton {

                border-color: $primary_color;
                background: $primary_color;
            }
        }

        &.Blur {

            opacity: 0.4;

            &:hover {

                opacity: 0.8;
            }
        }

        .Label {

            border-radius: 9px;
            background-color: $white;
            height: 18px;
            position: absolute;
            left: 50%;
            top: -10px;
            transform: translateX(-50%);
            padding: 1px 5px;
            font-family: $primary_font;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 13px;
            letter-spacing: normal;
            text-align: center;
            color: $primary_color;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            -webkit-touch-callout: none;
        }

        .Content {

            flex: 1;

            .Title,
            .Description {

                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                user-select: none;
                -webkit-touch-callout: none;
                transition: opacity linear 150ms;
                opacity: 1;
            }

            .Title {

                font-family: $primary_font;
                font-size: 17px;
                line-height: 1.29;
                color: $brand_text;
                display: block;
                width: 100%;
            }

            .Description {

                font-family: $primary_font;
                font-size: 13px;
                line-height: 1.23;
                color: $secondary-text-color;
                display: block;
                width: 100%;
                padding-top: 15px;
            }

            .Note {

                font-family: $primary_font;
                font-size: 13px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 16px;
                letter-spacing: normal;
                color: $primary_color;
                margin-top: 10px;
            }
        }

        &.Category {

            .Content {

                .Title {

                    font-size: 21px;
                    line-height: 22px;
                }
            }
        }

        &.Service {

            .Content {

                .Title {

                    font-size: 15px;
                    line-height: 1.47;
                }
            }
        }

        .ReadMore {

            display: inline-block;
            //color: $primary_color;
            color: $brand_text;
            padding-left: 4px;
        }

        .Extra {

            max-width: 80px;
            flex: 0 1 80px;
            width: 80px;
            padding-left: 23px;

            &.ClearLeft {

                padding-left: 0;
            }

            .ServiceInfo {

                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                position: relative;
                user-select: none;
                -webkit-touch-callout: none;

                .Price,
                .OldPrice {

                    font-family: $primary_font;
                    font-size: 21px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 21px;
                    letter-spacing: normal;
                    text-align: right;
                    margin-bottom: 1px;
                    position: relative;
                    text-transform: uppercase;
                }

                .OldPrice {

                    color: $brand_text_alpha_50;

                    &::after {

                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        border-radius: 1px;
                        background: $crossed_red;
                        top: 50%;
                        left: 0;
                        margin-top: -1px;
                        transform: translateY(-50%);
                    }
                }

                .Price {

                    color: $brand_text;
                }

                .Duration {

                    font-family: $primary_font;
                    font-size: 15px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.47;
                    letter-spacing: normal;
                    text-align: right;
                    color: $secondary-text-color;
                }

                .DetailedDescription {

                    position: absolute;
                    top: -22px;
                    right: 0;
                    width: 22px;
                    height: 22px;

                    svg {

                        width: 13px;
                        height: 13px;
                        display: block;
                        margin-left: auto;

                        .infoStroke {

                            stroke: $primary_color;
                        }

                        .infoFill {

                            fill: $primary_color;
                        }
                    }
                }
            }

            .ServiceInfo,
            .Employees,
            .Total {

                transition: opacity linear 150ms;
                opacity: 1;
                user-select: none;
                -webkit-touch-callout: none;
            }

            .Employees {

                object-fit: contain;
                border-radius: 13px;
                background-color: rgba(223, 228, 234, 0.5);
                width: 100%;
                min-height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $primary_font;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $brand_text;

                svg {

                    stroke: $primary_color;
                    width: 9px;
                    height: 9px;
                    display: block;
                    margin-right: 8px;
                }
            }

            .ActionButton {

                font-family: $primary_font;
                font-size: 13px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 16px;
                letter-spacing: normal;
                color: $primary_color;
                margin-top: 10px;
                user-select: none;
                -webkit-touch-callout: none;
            }

            .Total {

                font-family: $primary_font;
                font-size: 28px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 22px;
                letter-spacing: normal;
                text-align: right;
                color: $brand_text;
            }
        }

        &.Service {

            .Extra {

                max-width: 120px;
                flex: 0 1 120px;
                width: 120px;
                padding-left: 10px;
            }
        }

        &.Disabled {

            .Content {
                .Title,
                .Description {
                    opacity: 0.3;
                }
            }

            .Extra {

                .Total,
                .Employees,
                .ServiceInfo {
                    opacity: 0.3;
                }
            }
        }
    }
}
