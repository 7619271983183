@import "../variables";

.TermsField {

    &,
    :global(.cleanslate) & {
        display: flex;
        justify-content: center;
        height: 42px;
        object-fit: contain;
        margin: 38px auto;

        .Container {
            display: flex;
            min-width: 224px;
            background-color: #f3f3f3;
            border-radius: 21px;
            border: 1px solid transparent;
            transition: border ease-in-out 150ms;
            justify-content: space-between;

            &:hover {
                background-color: rgba(243, 243, 243, .5);
            }

            &.Error {
                border: 1px solid $crossed_red;
            }

            label {
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                background: none;
                border: 0;
                flex-direction: row;
                justify-content: center;
                padding: 20px 5px 20px 20px;

                * {
                    cursor: pointer;
                }

                :hover + .Box {
                    border: 2px solid $primary_color;
                }

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    cursor: pointer;
                    overflow: hidden;
                    margin: 0;
                    padding: 0;

                    &:checked + .Box {
                        background-color: $primary_color;
                        border: 2px solid $primary_color;

                        svg {
                            transition: fill linear 150ms;
                            fill: $white;
                        }
                    }
                }

                span {
                    font-family: $primary_font;
                    font-size: 13px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #364a63;

                    &.Text {
                        margin-top: 2px;
                    }
                }

                .Box {
                    height: 14px;
                    width: 14px;
                    border-radius: 3px;
                    border: solid 1px $secondary-text-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    background-color: $white;
                    margin-right: 11px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    transition: all .15s linear;

                    svg {
                        fill: $white;
                    }
                }
            }
        }

        .LinkArrow {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 20px;

            * {
                cursor: pointer;
            }

            svg {
                height: 10px;
                width: 10px;
                fill: $brand_text;
                transition: linear fill 150ms;
            }
        }

        &:hover {
            .LinkArrow {
                svg {
                    fill: $primary_color;
                }
            }
        }
    }
}
