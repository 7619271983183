@import "../variables";

.ScheduleItem {

    &,
    :global(.cleanslate) & {
        display: flex;
        font-family: $primary_font;
        font-size: 21px;
        pointer-events: auto;
        cursor: pointer;
        justify-content: center;
        width: 100%;
        min-height: 78px;
        position: relative;
        border-top: 1px solid transparent;
        background-color: $white;
        transition: border-top-color linear 150ms,
        opacity linear 150ms,
        background-color linear 150ms;
        opacity: 1;
        border-top: solid 0.9px $selected_border_color;
        align-items: center;
        color: $brand_text;
        user-select: none;
        -webkit-touch-callout: none;

        * {
            cursor: pointer;
        }

        &:last-child {
            border-bottom: solid 0.9px $selected_border_color;
        }

        &::before {

            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 100%;
            margin-top: -1px;
            background-color: transparent;
            opacity: 1;
            transition: background-color linear 150ms,
            opacity linear 150ms,
            width ease-in 150ms;
        }

        &::after {

            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            margin-top: -1px;
            background-color: transparent;
            opacity: 1;
            transition: background-color linear 150ms,
            opacity linear 150ms,
            width ease-in 150ms;
        }

        &.ItemSelected {

            //background-color: rgba(239, 242, 244, 0.5);
            background-color: $selected_color;
            border-top-color: $selected_border_color;

            &::before, &::after {

                width: 6px;
                background-color: $primary_color;
                opacity: 0.5;
            }
        }

        .TimeFormat {
            color: #97a2b0;
            margin-left: 7px;
            text-transform: uppercase;
        }
    }
}

.Separator {
    &,
    :global(.cleanslate) & {
        width: 100%;
        height: 5px;
        background-color: $secondary-text-color;
        opacity: 0.1;
    }
}
