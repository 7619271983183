@import "../variables";

.ScheduleMonthSelection {

    &,
    :global(.cleanslate) & {
        display: flex;
        min-height: 70px;
        align-items: center;

        .Content {
            display: flex;
            justify-content: center;
            width: 100%;
            text-align: center;
            padding: 30px 42px 20px 42px;

            .MonthName {

                user-select: none;
                -webkit-touch-callout: none;
                width: 100%;
                font-family: $primary_font;
                font-size: 15px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.47;
                color: $brand_text;
                position: relative;
                transform: translate3d(0, 0, 0);
                text-transform: capitalize;
            }

            .Arrow {

                user-select: none;
                -webkit-touch-callout: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 40px;
                justify-content: center;

                * {
                    cursor: pointer;
                }

                svg {

                    fill: $secondary_color;
                    display: block;
                    width: 10px;
                    height: 11px;
                }
            }
        }
    }
}