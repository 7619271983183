@import '../variables';

.Wrap {

    &,
    :global(.cleanslate) & {

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        svg {
            width: 315px;
            height: 150px;
            margin: 50px 0;
        }

        .Text {
            font-family: $primary_font;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: $secondary-text-color;
            width: 100%;
            max-width: 280px;
            text-align: center;
            margin: 0 auto;
        }

        .Button {
            min-height: 44px;
            border-radius: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            font-family: $primary_font;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: normal;
            cursor: pointer;
            border: 1px solid transparent;
            transition: border linear 150ms,
            background ease-in 150ms,
            color linear 150ms;
            background: $primary_color;
            color: $white;
            padding: 10px 45px;
            margin-top: 50px;

            &:hover {
                background: $white;
                color: $primary_color;
                border-color: $primary_color;
            }

            * {
                cursor: pointer;
            }
        }
    }
}
