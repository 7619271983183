@import "../variables";

.Wrapper {
    &,
    :global(.cleanslate) & {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 -5px;

        &:global(.hide) {
            display: none;
        }
    }
}

.Field,
input.Field {

    &,
    :global(.cleanslate) & {
        min-width: 0;
        border-radius: 5px;
        border: none;
        background-color: $white;
        height: 50px;
        width: 100%;
        box-shadow: none;
        outline: none;
        font-family: $primary_font;
        font-size: 25px;
        line-height: 25px;
        font-weight: 500;
        text-align: center;
        caret-color: transparent;
        color: $primary_color;
        transition: color ease-in-out 150ms, border ease-in-out 150ms;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        user-select: none;
        -webkit-touch-callout: none;
        max-width: 40px;
        padding: 5px 0 0 0;

        &::-ms-clear {
            display: none;
        }

        &:focus {
            color: $primary_color;
        }

        &::-moz-selection {
            background: $white;
            color: $primary_color;
            text-shadow: none;
        }

        &::selection {
            background: $white;
            color: $primary_color;
            text-shadow: none;
        }

        &[disabled] {
            background-color: rgba($white, 0.5);
        }
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.FieldWrapper {
    &,
    :global(.cleanslate) & {
        display: block;
        width: auto;
        padding: 0 5px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 0;
            max-width: 20px;
            height: 2px;
            bottom: 15px;
            left: 50%;
            border-radius: 1px;
            background-color: transparent;
            z-index: 2;
            transform: translateX(-50%);
            transition: ease-in-out background-color 150ms;
            animation: none;
        }

        &.HasFocus {

            &::after {
                width: 100%;
                background-color: $primary_color;
                animation: blink 1s step-end infinite;
            }
        }

        &.Disabled {

            &::after {
                transition: none;
                content: 'x';
                position: absolute;
                width: 100%;
                text-align: center;
                background-color: transparent;
                font-family: $primary_font;
                font-size: 25px;
                color: rgba($white, 0.5);
                font-weight: 500;
                left: 50%;
                top: 0;
                transform: translate(-50%, 0);
                padding-top: 11px;
            }
        }

        &.Failed {

            .Field {

                &::-moz-selection {
                    background: $white;
                    color: $error;
                    text-shadow: none;
                }

                &::selection {
                    background: $white;
                    color: $error;
                    text-shadow: none;
                }

                &,
                &:focus {
                    color: $error;
                }
            }
        }
    }
}
