@import "../variables";

.SelectContainer {

    &,
    :global(.cleanslate) & {

        :global(.Select__control) {
            border: 0;
            min-height: unset;
            width: 95%;
            box-shadow: none;
            background: transparent;
            caret-color: $primary_color;
            font-family: $primary_font;
            font-size: 21px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.05;
            letter-spacing: normal;

            :global(.Select__value-container) {
                padding: 0;
                position: relative;

                &:after {
                    content: "";
                    border-color: $primary_color transparent transparent;
                    border-style: solid;
                    border-width: 5px 4px 0;
                    height: 0;
                    position: absolute;
                    width: 0;
                    right: 0;
                }
            }

            :global(.Select__indicators) {
                display: none;
            }
        }

        :global(.Select__menu) {

            font-family: $primary-font;

            :global(.Select__option) {

                background-color: $white;
                cursor: pointer;

                * {
                    cursor: pointer;
                }

                &:global(.Select__option--is-focused) {
                    background-color: $primary_color_alpha_22;
                }

                &:global(.Select__option--is-selected) {
                    background-color: $primary_color;
                }
            }
        }
    }
}

