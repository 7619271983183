@import "../variables";

.PhoneFieldContainer {

    &,
    :global(.cleanslate) & {
        display: flex;

        .PhoneField {
            position: relative;
            margin-left: 30px;
            width: 100%;
        }

        .PhonePrefix {
            display: flex;
            text-align: left;
            cursor: pointer;

            .PhoneInput {
                margin-left: 5px;
            }

            :global(.css-1laao21-a11yText) {
                z-index: 9999;
                border: 0;
                -webkit-clip: rect(1px,1px,1px,1px);
                clip: rect(1px,1px,1px,1px);
                height: 1px;
                width: 1px;
                position: absolute;
                overflow: hidden;
                padding: 0;
                white-space: nowrap;
            }

            :global(.css-1pahdxg-control) {
                -webkit-box-align: center;
                align-items: center;
                background-color: $white;
                -webkit-box-pack: justify;
                justify-content: space-between;
                box-sizing: border-box;
                outline: 0 !important;
            }

            :global(.css-26l3qy-menu) {
                top: 100%;
                background-color: $white;
                margin-bottom: 8px;
                position: absolute;
                width: 100%;
                z-index: 1;
                box-sizing: border-box;
            }

            :global(.css-9gakcf-option) {
                display: block;
                padding: 8px 12px;
                width: 100%;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                box-sizing: border-box;
            }

            :global(.css-yt9ioa-option) {
                background-color: transparent;
                display: block;
                padding: 8px 12px;
                width: 100%;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                box-sizing: border-box;
            }

            :global(.css-16tawd9-MenuList),
            :global(.css-11unzgr) {
                max-height: 300px;
                overflow-y: auto;
                padding-bottom: 4px;
                position: relative;
                box-sizing: border-box;
            }

            :global(.css-1n7v3ny-option) {
                background-color: #DEEBFF;
                color: inherit;
                cursor: default;
                display: block;
                font-size: inherit;
                padding: 8px 12px;
                width: 100%;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0,0,0,0);
                box-sizing: border-box;
            }

            :global(.css-2b097c-container) {
                position: relative;
                box-sizing: border-box;
            }

            :global(.css-b8ldur-Input) {
                margin: 2px;
                padding-bottom: 2px;
                padding-top: 2px;
                visibility: visible;
                color: hsl(0,0%,20%);
                box-sizing: border-box;
            }

            .SelectedValueContainer {
                position: relative;
                width: 100%;

                span {
                    white-space: nowrap;
                    vertical-align: top;
                    color: $brand_text;
                    font-family: $primary_font;
                    margin: 0;
                    font-size: 21px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.05;
                    letter-spacing: normal;
                }

                &:after {
                    content: "";
                    border-color: $primary_color transparent transparent;
                    border-style: solid;
                    border-width: 5px 4px 0;
                    height: 0;
                    margin-left: 7px;
                    margin-top: 8px;
                    position: absolute;
                    width: 0;
                }
            }

            :global(.PhonePrefix__control) {
                display: flex;
                flex-wrap: nowrap;
                position: relative;
                outline: 0;
                transition: all 100ms;
                min-width: 100px;
                max-width: 100%;
                padding: 4px;
                border: 1px solid #e1dcff;
                border-radius: 0;
                cursor: text;
                min-height: unset;
                border-bottom: none;

                &:global(.PhonePrefix__control--menu-is-open) {
                    border-color: #e1dcff;
                    border-radius: 0;
                    box-shadow: none;

                    &:hover {
                        border-color: #e1dcff;
                    }
                }

                :global(.PhonePrefix__value-container) {
                    border: 1px solid #e1dcff;
                    border-radius: 3px;
                    height: 25px;

                    div {
                        margin: 0;
                        padding: 0;
                    }

                    :global(.PhonePrefix__input) {
                        display: block;
                    }
                }

                :global(.PhonePrefix__indicators) {
                    display: none;
                }
            }

            :global(.PhonePrefix__menu) {
                margin-top: 0;
                box-shadow: none;
                border: 1px solid #e1dcff;
                border-top: 0;
                border-radius: 0;

                :global(.PhonePrefix__menu-list) {
                    padding-top: 0;

                    :global(.PhonePrefix__menu-notice) {
                        padding: 2px;
                    }
                }

                :global(.PhonePrefix__option) {
                    cursor: pointer;
                    text-transform: uppercase;
                    font-size: 11px;
                    line-height: 11px;
                    font-family: $primary_font;
                    font-weight: 600;
                    color: #7789ad;
                    text-align: left;

                    * {
                        cursor: pointer;
                    }

                    &:global(.PhonePrefix__option--is-focused) {
                        background-color: $primary_color_alpha_22;
                        color: $white;
                    }

                    &:global(.PhonePrefix__option--is-selected) {
                        background-color: $primary_color;
                        color: $white;
                    }
                }
            }

            :global(.PhonePrefix__single-value) {
                line-height: normal;
                color: $primary_color;
                font-size: 11px;
                font-weight: 600;
                font-family: $primary_font;
                position: relative;
                margin-left: 8px;
            }

            .Menu {
                position: absolute;
                width: 100%;
                min-width: 100px;
                max-width: 100%;
                top: 47px;
                left: 0;
                z-index: 2;

            }
        }
    }
}

