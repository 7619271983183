@import '../variables';

.Loading {

    &,
    :global(.cleanslate) & {
        width: 100%;
        max-width: 200px;
        max-height: 100%;
        margin: auto;
        display: block;
        padding: 34px 0;

        &.full_screen {

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }
}

.Loader {

    &,
    :global(.cleanslate) & {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}

.Loader {
    &,
    :global(.cleanslate) & {
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border: 2px solid $primary_shadow;
        border-left-color: $primary-color;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: SLifeLoad8 1.1s infinite linear;
        animation: SLifeLoad8 1.1s infinite linear;
        margin: auto;
    }
}

@-webkit-keyframes SLifeLoad8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes SLifeLoad8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
